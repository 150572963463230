<template>
  <div class="px-4 mt-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Ventas (últ. 7d.)"
          :value=formatoImporte(totalVentas.bruto,true)
          :percentage="{
            value: totalVentas.incremento + '%',
            color: totalVentas.incremento>0 ? 'text-success' : 'text-danger',
          }"
          :icon="{
            component: 'ni ni-cart',
            background: iconBackground,
          }"
          :adicional="totalVentas.cuantos + ' ventas finalizadas'"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Usuarios activos (últ. 7d.)"
          :value="totalUsuarios.activos"
          :percentage="{
            value: totalUsuarios.incremento + '%',
            color: totalUsuarios.incremento>0 ? 'text-success' : 'text-danger',
          }"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground,
          }"
          :adicional="totalUsuarios.cuantos + ' activos el últ. año'"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Productos activos (últ. 7d.)"
          :value="totalProductos.activos"
          :percentage="{
            value: totalProductos.incremento + '%',
            color: totalProductos.incremento>0 ? 'text-success' : 'text-danger',
          }"
          :icon="{
            component: 'ni ni-box-2',
            background: iconBackground,
          }"
          :adicional="totalProductos.cuantos + ' activo últ. año'"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Tipsters activos (últ. 7d.)"
          :value="totalTipsters.activos"
          :percentage="{
            value: totalTipsters.incremento + '%',
            color: totalTipsters.incremento>0 ? 'text-success' : 'text-danger',
          }"
          :icon="{
            component: 'ni ni-briefcase-24',
            background: iconBackground,
          }"
          :adicional="totalTipsters.cuantos + ' activo últ. año'"
          direction-reverse
        />
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-8 col-md-6">
        <div class="card z-index-2 mb-5">
          <gradient-line-chart
            id="chart-line"
            title="Evolución de ventas"
            :description="'<i class=\'fa fa-arrow-up ' + (graficoVentas.incremento<0 ? 'text-danger' : 'text-success') + ' \'></i><span class=\'font-weight-bold\'> ' + graficoVentas.incremento + '% </span> en ' + graficoVentas.actual"
            :loading="loading"
            :chart="{
              labels: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
              datasets: [
                {
                  label: graficoVentas.actual,
                  data: graficoVentas.serie_act,
                  tension: 0.4,
                  borderWidth: 3,
                  borderColor: '#1d91dc',
                  backgroundColor: gradientStroke1,
                  fill: true,
                },
                {
                  label: graficoVentas.anterior,
                  data: graficoVentas.serie_ant,
                  tension: 0.4,
                  borderWidth: 3,
                  borderColor: '#66c0a6',
                  backgroundColor: gradientStroke1,
                  fill: true,
                },
              ],
            }"
          />
        </div>
        <projects-card 
          title="Ventas por Tipster"
          :loading="loading"
          :cuantos="totalTipsters.activos + ' tipsters'"
          :ventas="ventasTipsters"
        />
      </div>

      <!-- Columna derecha --> 
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Últimas ventas"
          description="<i class='fa fa-arrow-right text-success' aria-hidden='true'></i> Ventas más recientes"
        >
          <TimelineItem v-for="v in ventasTodas" :key="v.id"
            color="info"
            icon="check-bold"
            :title="v.nomproducto + ' - ' + v.nomtipster"
            :date-time="v.fecha"
            :texto="formatoImporte(v.precio,true)"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import TimelineList from "./components/TimelineList.vue";
import TimelineItem from "./components/TimelineItem.vue";
import ProjectsCard from "./components/ProjectsCard.vue";
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-tipster",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      totalVentas: {},
      totalUsuarios: {},
      totalProductos: {},
      totalTipsters: {},
      graficoVentas: {},
      ventasTipsters: {},
      ventasTodas: {},
      loading: true,
    };
  },
  mixins: [globalFunctionsMixin],
  components: {
    MiniStatisticsCard,
    GradientLineChart,
    ProjectsCard,
    TimelineList,
    TimelineItem,
  },

  async mounted() { 
    try {
        this.loading = true;
        const resp = await this.$store.dispatch("auth/estadisticas");
        if (resp.res=='OK') {
            this.totalVentas = resp.ventas['totales']; 
            this.graficoVentas = resp.ventas['grafico']; 
            this.totalUsuarios = resp.usuarios['totales']; 
            this.totalProductos = resp.productos['totales']; 
            this.totalTipsters = resp.tipsters['totales']; 
            this.ventasTipsters = resp.tipsters['ventas']; 
            this.ventasTodas = resp.ventas['todas']; 
            console.log(this.graficoVentas.serie_act);
        }
        this.loading = false;
    } 
    catch (error) {
        console.log('Error recuperando estadisticas');
    }
  },
};
</script>
