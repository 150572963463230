<template>
    <h5>Alta de canal</h5>
    <Form role="form" class="text-start" :validation-schema="schema" @submit="handleAltaCanal">

        <soft-field
            id="nombre"
            v-model="canal.nombre"
            type="text"
            placeholder="Indique nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <soft-select v-if="miRol==1"
            id="tipsterid"
            v-model="canal.tipsterid"
            name="tipsterid"
            :valores="tipsters"
            placeholder="Seleccione un Tipster"
            class="mb-2"
        />

        <p style="font-size:13px;margin-top:12px;"><b>NOTA:</b> Tras el alta deberá poner al bot <b>{{ botName }}</b> como administrador de su grupo de Telegram y ejecutar el comando <b>/initCanal</b> con el código generado tras el alta</p>
  
        <soft-button
            class="float-end mt-3 px-5"
            color="tipster"
            :is-disabled="loading ? true : false"
            >
            <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
</template>
  
<script>
  import SoftField from "@/components/SoftField.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaCanalForm",
    components: {
        SoftField,
        SoftSelect,
        SoftButton,
        Form,
    },
    props: {
        canalBotName: String,
    },
    data() {
        const schema = yup.object().shape({
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
        });

        const miRol = localStorage.getItem("usuarioRolId");
        var roles = [ {id:1,nombre:'Administrador'}, {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        if (miRol==2) {
            roles = [ {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        }
        
        return {
            loading: false,
            canal:{
                nombre: '',
                tipsterid: miRol==2 ? localStorage.getItem("usuarioTipsterId") : null
            },
            schema,
            roles: roles,
            tipsters: [],
            miRol: miRol,
            botName: this.canalBotName
        };
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleAltaCanal() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        console.log('Formulario enviado:', this.canal);
        this.loading = true;
        try {
            const resp = await this.$store.dispatch("auth/guardaCanal", this.canal);
            this.loading = false;
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Se ha registrado la nueva campaña. Indique /initCanal " + resp.uuid + " en su grupo de Telegram para obtener estadísticas",
                    width: 600,
                    timer: 10000
                });
                this.$emit('cerrar-modal');
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      },
    },
    async mounted() { 
        this.tipsters = await this.$store.dispatch("auth/listaTipsters");
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>