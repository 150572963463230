<template>
    <h5>Modificar Campaña</h5>
    <Form role="form" class="text-start" :validation-schema="schema" @submit="handleEditaCampana">

        <soft-field
            id="nombre"
            v-model="canal.nombre"
            type="text"
            placeholder="Indique nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <p v-if="canalEstado=='I'" style="margin:0;font-size:14px;color:#333;">Inicialice su grupo de Telegram con este comando: <b>/initCampana {{ canalUuid }}</b></p>

        <soft-button
            class="float-end mt-3 px-5"
            color="tipster"
            :is-disabled="loading ? true : false"
            >
            <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "EditaCampanaForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    props: {
        canalId: Number,
        canalEstado: String,
        canalUuid: String,
        canalNombre: String
    },
    data() {
        const schema = yup.object().shape({
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
        });

        return {
            loading: false,
            canal:{
                id: this.canalId,
                nombre: this.canalNombre,
                estado: this.canalEstado,
                uuid: this.canalUuid
            },
            schema,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleEditaCampana() {    console.log('handleEditaCampana');
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try { 
                const resp = await this.$store.dispatch("auth/guardaCampana", this.canal);
                this.loading = false;
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha actualizado el canal",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>