import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
//import Tables from "@/views/Tables.vue";
//import Billing from "@/views/Billing.vue";
//import VirtualReality from "@/views/VirtualReality.vue";
//import ProfileTemplate from "../views/ProfileTemplate.vue";
//import Rtl from "@/views/Rtl.vue";
//import SignIn from "@/views/SignIn.vue";
//import SignUp from "@/views/SignUp.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import SendEmail from "../views/SendEmail.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Profile from "@/views/Profile.vue";
import Tipsters from "../views/Tipsters.vue";
import Usuarios from "../views/Usuarios.vue";
import Productos from "../views/Productos.vue";
import Pedidos from "../views/Pedidos.vue";
import Configuracion from "../views/Configuracion.vue";
import Canales from "../views/Canales.vue";
import Campanas from "../views/Campanas.vue";
import GrupoTelegram from "../views/GrupoTelegram.vue";
import Pronostico from "../views/Pronostico.vue";
import store from "/src/store";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextPipeline });
  };
}


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [auth],
    },
  },/*
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/profileTemplate",
    name: "ProfileTemplate",
    component: ProfileTemplate,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
    meta: {
      middleware: [auth],
    },
  },*/
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],  
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/email",
    name: "SendEmail",
    component: SendEmail,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/pronostico/:uuid",
    name: "Pronostico",
    component: Pronostico,
    props: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/tipsters",
    name: "Tipsters",
    component: Tipsters,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productos",
    name: "Productos",
    component: Productos,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/pedidos",
    name: "Pedidos",
    component: Pedidos,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuracion",
    name: "Configuracion",
    component: Configuracion,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/canales",
    name: "Canales",
    component: Canales,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/campanas",
    name: "Campanas",
    component: Campanas,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/grupo-telegram/:idCanal",
    name: "GrupoTelegram",
    component: GrupoTelegram,
    meta: {
      middleware: [auth],
    },
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    store,
  };

  //if (!to.meta.middleware) {
  //  auth({ ...context, next: next });
  //}
  const middleware = to.meta.middleware;

  // Si no hay middleware definido, continuar la navegación
  if (!middleware || middleware.length === 0) {
    return next();  // Continuar si no hay middleware
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
