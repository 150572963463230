<template>
    <h5>Detalle del pedido:</h5>
    <p class="item-detalle mt-2 mb-2"><span>Referencia:</span>{{ pedido.referencia }}</p>
    <p class="item-detalle mt-2 mb-2"><span>Fecha:</span>{{ pedido.created_at }}</p>
    <p class="item-detalle mt-2 mb-2"><span>Producto:</span>{{ pedido.nomproducto }}</p>
    <p class="item-detalle mt-2 mb-2"><span>Tipster:</span>{{ pedido.nomtipster }}</p>
    <p class="item-detalle mt-2 mb-2"><span>Precio:</span>{{ pedido.precio }}</p>
    <p class="item-detalle mt-2 mb-2"><span>Estado:</span>{{ estadoPedido(pedido.estado) }}</p>
</template>
  
<script>
import showSwal from "/src/mixins/showSwal.js";

export default {
    name: "EditaPedidoForm",
    components: {
    },
    props: {
        pedidoId: String,
    },
    data() {
        const miRol = localStorage.getItem("usuarioRolId");
        return {
            loading: false,
            pedido:{
                referencia: '',
                created_at: '',
                nomproducto: '',
                nomtipster: '',
                precio: '',
                estado: ''
            },
            miRol: miRol
        };
    },
    watch: {
        pedidoId(newVal, oldVal) {
            console.log("pedidoId cambió de", oldVal, "a", newVal);
            this.syncPedido();
        },
    },
    emits: ['cerrar-modal'],
    methods: {
        async syncPedido() { console.log("syncPedido");
            const resp = await this.$store.dispatch("auth/detallePedido", {id: this.pedidoId});
            if (resp.res=='OK') {
                this.pedido = resp.data;
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        },
        estadoPedido(estado) {
            if (estado=='C') {
                return 'Confirmado';
            }
            else if (estado=='P') {
                return 'Pendiente';
            }
            else {
                return estado;
            }
        }
    },
    mounted() {
        if (this.pedidoId) {
            this.syncPedido();
        }
    },
};
</script>
  
<style scoped lang="scss">
.item-detalle {
    span { width: 100px; display: inline-block;font-weight: bold;}
}
</style>