import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/api/v2/';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "login", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },

  async logout() {
    try {
      await axios.post(API_URL + "logout", {}, { headers: authHeader() });
    } finally {
      localStorage.removeItem("userF");
    }
  },

  async register(user) {
    const response = await axios.post(API_URL + "register", user);
    if (response.data) {
      return response.data;
    }
  },

  async claveOlvidada(user) {
    const response = await axios.post(API_URL + "claveOlvidada", user);
    if (response.data) {
      return response.data;
    }
  },

  async codigoRecuperado(user) {
    const response = await axios.post(API_URL + "codigoRecuperado", user);
    if (response.data) {
      return response.data;
    }
  },

  async validaCodigo(user) {
    const response = await axios.post(API_URL + "validaCodigo", user);
    if (response.data) {
      return response.data;
    }
  },

  async establecerClave(user) {
    const response = await axios.post(API_URL + "establecerClave", user);
    if (response.data) {
      return response.data;
    }
  },

  // TIPSTERS
  async tipsters(tipster) {
    const response = await axios.post(API_URL + "tipsters", tipster, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async listaTipsters() {
    const response = await axios.post(API_URL + "listaTipsters", null, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaTipster(tipster) { 
    const response = await axios.post(API_URL + "guardaTipster", tipster, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async borraTipster(userId) { 
    const response = await axios.post(API_URL + "borraTipster", userId, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },


  // USUARIOS
  async usuarios(usuario) {
    const response = await axios.post(API_URL + "usuarios", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaUsuario(usuario) { 
    const response = await axios.post(API_URL + "guardaUsuario", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async borraUsuario(userId) { 
    const response = await axios.post(API_URL + "borraUsuario", userId, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async generarClaveUsuario(usuario) { 
    const response = await axios.post(API_URL + "generarClaveUsuario", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },


  // PRODUCTOS
  async productos(producto) {
    const response = await axios.post(API_URL + "productos", producto, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaProducto(tipster) { 
    const response = await axios.post(API_URL + "guardaProducto", tipster, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async consultaProducto(uuid) {
    const response = await axios.post(API_URL + "consultaProducto", uuid);
    if (response.data) {
      return response.data;
    }
  },

  async borraProducto(userId) { 
    const response = await axios.post(API_URL + "borraProducto", userId, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async validaEmailUsuario(email) {
    const response = await axios.post(API_URL + "validaEmailUsuario", email);
    if (response.data) {
      return response.data;
    }
  },

  // PEDIDOS
  async pedidos(pedido) {
    const response = await axios.post(API_URL + "pedidos", pedido, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async detallePedido(pedido) {
    const response = await axios.post(API_URL + "detallePedido", pedido, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },
  
  async generaPedido(user) {
    const response = await axios.post(API_URL + "generarPedido", user);
    if (response.data) {
      return response.data;
    }
  },

  async confirmarPagado(user) {
    const response = await axios.post(API_URL + "confirmarPagado", user);
    if (response.data) {
      return response.data;
    }
  },

  // ESTADISTICAS
  async estadisticas() {
    const response = await axios.post(API_URL + "estadisticas", {}, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  // CONFIGURACION
  async configuracion(configuracion) {
    const response = await axios.post(API_URL + "configuracion", configuracion, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaConfiguracion(configuracion) { 
    const response = await axios.post(API_URL + "guardaConfiguracion", configuracion, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  // CANALES
  async canales(usuario) {
    const response = await axios.post(API_URL + "canales", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaCanal(usuario) { 
    const response = await axios.post(API_URL + "guardaCanal", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async borraCanal(userId) { 
    const response = await axios.post(API_URL + "borraCanal", userId, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async listaCanales(tipster) {
    const response = await axios.post(API_URL + "listaCanales", tipster, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  // CAMPANAS
  async campanas(usuario) {
    const response = await axios.post(API_URL + "campanas", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async guardaCampana(usuario) { 
    const response = await axios.post(API_URL + "guardaCampana", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async borraCampana(userId) { 
    const response = await axios.post(API_URL + "borraCampana", userId, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },

  async grupoTelegram(usuario) {
    const response = await axios.post(API_URL + "grupoTelegram", usuario, { headers: authHeader() });
    if (response.data) {
      return response.data;
    }
  },
};
