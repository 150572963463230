<template>
  <div class="mt-4 px-4 container-fluid">
    <div class="breadcrumb text-md px-0 mb-4">
      <p><span class="breadcrumb-item" @click="irInicio">Inicio</span> > <span class="breadcrumb-item" @click="irCanal">Canales</span> > Detalle canal de Telegram</p>
    </div>
  </div>
  
  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-buscador">
          <div class="titulo"><h5 class="mx-4 mt-5 mb-5">Movimientos del canal de Telegram</h5></div>
          <div class="table-search">
            <soft-input id="textoBuscador" type="text" placeholder="Búsqueda rápida..." aria-label="Búsqueda rápida" @input="teclearBuscador"/>
          </div>
          <div class="descarga doble">
            <p @click="descargarCSV"><i class="fa fa-download" aria-hidden="true"></i></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-listado mt-4">
          <div class="px-4 pb-0 card-body">
            <div class="table-responsive mb-4">
              <table id="canales-list" class="table table-flush table-listado">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortTable('0')" :class="sortColumn=='0' ? sortDirection : ''">Mensaje</th>
                    <th @click="sortTable('1')" :class="sortColumn=='1' ? sortDirection : ''" data-type="date" data-format="DD/MM/YYYY">Fecha</th>
                    <th @click="sortTable('2')" :class="sortColumn=='2' ? sortDirection : ''">Acción</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <!-- Ciclo a través de los elementos filtrados y paginados -->
                  <tr v-for="row in filas" :key="row.id">
                    <td>{{ row.id }}</td>
                    <td>{{ row.fecha }}</td>
                    <td>{{ row.accion }}</td>
                  </tr>
                  <tr v-if="filas.length==0"><td colspan="7">No hay registros que mostrar</td></tr>
                </tbody>
              </table>
            </div>
            <!-- Control de la Paginacion manual -->
            <div class="paginacion" v-if="filas.length>0">
              <p class="contador-listado">Mostrando {{ mostradosIni }} a {{ mostradosFin }} de {{ mostradosTotal }} registros</p>

              <ul class="pagination pagination-md" v-if="paginaTotal>1">
                <li class="page-item prev-page" v-if="paginaActual>1">
                  <a class="page-link" aria-label="Previous" @click="paginaActual=paginaActual-1;fetchAndSetGrupos();">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </a>
                </li>
                <li v-for="index in rangoPaginas" :key="index" class="page-item" :class="index==paginaActual ? 'active disabled' : ''">
                  <a class="page-link" @click="paginaActual=index;fetchAndSetGrupos();">{{index}}</a>
                </li>
                <li class="page-item next-page" v-if="paginaActual<paginaTotal">
                  <a class="page-link" aria-label="Next" @click="paginaActual=paginaActual+1;fetchAndSetGrupos();">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showSwal from "@/mixins/showSwal.js";
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';

export default {
  name: "GrupoTelegram",
  components: {
  },
  mixins: [globalFunctionsMixin],
  data() {
    return {
      filas: [],
      canalId: 0,
      textoBuscador: '',
      paginaActual: 0,
      paginaTotal: 0,
      mostrarBorrados: false,
      mostradosIni: 0,
      mostradosFin: 0,
      mostradosTotal: 0,
      sortColumn: 0,
      sortDirection: '',
      miRol: localStorage.getItem("usuarioRolId")
    };
  },
  computed: {
    rangoPaginas() {
      let startPage = Math.max(this.paginaActual - 3, 1);
      let endPage = Math.min(this.paginaActual + 3,this.paginaTotal);
      let pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },

    async initializeDataTable() {

      this.sortColumn=1;
      this.sortDirection='desc';
      this.textoBuscador = '';
      this.paginaActual = 1;
      await this.fetchAndSetGrupos();

    },

    sortTable(key) {
      // Alternar entre ascendente y descendente al hacer clic en el encabezado
      if (this.sortColumn === key) {
        this.sortDirection = this.sortDirection=='asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = key;
        this.sortDirection = 'asc';
      }
      this.fetchAndSetGrupos();
    },

    teclearBuscador(event) {
      this.textoBuscador = event.target.value;

      let searchTimeout;
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        this.fetchAndSetGrupos();
      }, 500);
    },

    async fetchAndSetGrupos() {
      try {
        // Petición al servidor
        const response = await this.$store.dispatch("auth/grupoTelegram", { idCanal: this.$route.params.idCanal, textoBuscador: this.textoBuscador, paginaActual: this.paginaActual, mostrarBorrados: this.mostrarBorrados, sortColumn: this.sortColumn, sortDirection: this.sortDirection  }); 
        console.log(response);
        // Procesar los datos y actualizarlos en el DataTable
        this.filas = response.data.map(row => ({
          id: row.id,
          fecha: this.formatDate(row.fecha, 'DD/MM/YYYY HH:mm:ss'),
          accion: row.accion,
        }));

        this.paginaTotal = response.paginas;
        this.mostradosIni = (response.actual-1)*response.porpagina + 1;
        this.mostradosFin = Math.min(response.totales,response.actual*response.porpagina);
        this.mostradosTotal = response.totales;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    irInicio() {
      this.$router.push('/dashboard');
    },
    irCanal() {
      this.$router.push('/canales');
    }
  },

  async mounted() { 
    this.$store.state.showFooter = true;
    this.initializeDataTable();
  },

};
</script>



<style lang="scss" scoped>
.canales {
  background-color: rgba(0, 176, 234, 0.5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    h1,
    p {
      margin-bottom: 16px;
    }

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
