<template>
  <div class="row container-fluid">
    <div class="col-xs-1 col-sm-2 col-lg-3"></div>
    <div class="col-xs-10 col-sm-8 col-lg-6">
      <div v-if="resProducto=='OK'" class="compra-pronostico">
        <div>
          <h1 clas="mb-0">{{ producto.nombre }}</h1>
          <p class="nombre-tipster">{{ producto.tipstername }}</p>
          <p>PVP: {{ producto.precio }} €</p>
          <div v-if="loggedIn && mostrarUrl==''" style="max-width:400px;margin: 3rem auto;">
            <p class="mb-2" style="text-align:left;font-size:1.05rem;">Estás conectado como:</p>
            <soft-field
              id="emailLogged"
              v-model="emailLogged"
              type="text"
              placeholder="Indique su email"
              name="emailLogged"
              class="mb-2"
              largo="80"
              :readonly="true"
            />

            <PaymentStripe :clientSecret="clientSecret" :publicKey="publicKey" @cerrar-componente="mostrarEnlace" />
          </div>
          <div v-if="loggedIn && mostrarUrl!=''" style="max-width:400px;margin: 3rem auto;">
            <p class="mb-2" style="text-align:left;font-size:1.05rem;">Estás conectado como:</p>
            <soft-field
              id="emailLogged"
              v-model="emailLogged"
              type="text"
              placeholder="Indique su email"
              name="emailLogged"
              class="mb-2"
              largo="80"
              :readonly="true"
            />

            <p class="mt-5" style="text-align:left;font-size:1.05rem;">Este es tu enlace:</p>
            <a :href="mostrarUrl" target="_blank" style="display:block">{{ mostrarUrl }}</a>

            <button class="btn mb-0 btn-tipster btn-md float-end mt-4 px-4" @click="redirigirPedidos">
              <span class="text-xs">Ir al panel &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span>
            </button>
          </div>
          <div v-if="!loggedIn" style="max-width:400px;margin: 3rem auto;">
            <p class="mb-2" style="text-align:left;font-size:1.05rem;">Indícanos tu email:</p>
            <soft-field
              id="emailUnlogged"
              v-model="emailUnlogged"
              type="text"
              placeholder="Indique su email"
              name="emailUnlogged"
              class="mb-2"
              largo="80"
            />
            <button class="btn mb-0 btn-tipster btn-md float-end mt-4 px-4" @click="validarEmailCliente" v-if="!esUsuario && !permitirRegistro && !validarCodigo">
              <span class="text-xs">Validar &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span>
            </button>

            <div class="mt-3 mb-2" v-if="esUsuario">
              <p class="mb-2" style="text-align:left;font-size:1.05rem;">Indícanos tu clave:</p>
              <soft-field v-if="esUsuario"
                id="password"
                v-model="passwordUnlogged"
                type="password"
                placeholder="Indique su clave"
                name="password"
                class="mb-2"
              />
              <p class="mb-0" @click="claveOlvidada=true" style="font-style:initial;cursor:pointer;text-align:left;display:inline-block;line-height:3rem;font-size:1.1rem;">Olvidé mi clave</p>
              <button class="btn mb-0 btn-tipster btn-md float-end mt-4 px-4" @click="validarSesionCliente">
                <span class="text-xs">Iniciar sesión &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span>
              </button>
            </div>

            <div class="mb-2 mt-4" v-if="permitirRegistro">
              <p style="text-align:left;font-size:1.05rem;" class="mb-2">Indícanos tu nombre para registrarte:</p>
              <soft-field
                id="nombre"
                v-model="nameUnlogged"
                type="text"
                placeholder="Indique su nombre"
                name="nombre"
                class="mb-2"
                largo="80"
              />

              <div class="mt-4">
                <soft-switch 
                    id="indacepto" 
                    name="indacepto" 
                    @change="cambiarAceptacion()" 
                    style="display:inline-block;"
                />
                <label for="enviar-email" class="label-switch">Acepto el tratamiento informatizado de la presente información necesaria para la prestación de servicios y sus tareas derivadas</label>
              </div>

              <button class="btn mb-0 btn-tipster btn-md float-end mt-4 px-4" @click="registrarCliente">
                <span class="text-xs">Registrarme &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span>
              </button>
            </div>

            <div class="mb-2 mt-4" v-if="validarCodigo">
              <p style="text-align:left;font-size:1.05rem;" class="mb-2">Indícanos el código enviado a tu email:</p>
              <soft-field
                id="codigo"
                v-model="codeUnlogged"
                type="number"
                placeholder="Indique el código"
                name="codigo"
                class="mb-2"
                largo="6"
              />

              <button class="btn mb-0 btn-tipster btn-md float-end mt-4 px-4" @click="confirmarCodigo">
                <span class="text-xs">Confirmar &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></span>
              </button>
            </div>
          </div>

      <!--<div style="max-width:350px;margin:0 auto;" v-if="loggedIn"  @click="generarPedido">
            <soft-button
                class="float-end mt-5 px-5"
                color="tipster"
                :is-disabled="loading ? true : false"
                >
                <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Pagar &nbsp;<i class="fa fa-check" aria-hidden="true"></i></span>
            </soft-button>
          </div>-->
        </div>
      </div>
      <div v-if="resProducto=='NOK'">
        <p>{{ errorProducto }}</p>
      </div>
    </div>
    <div class="col-xs-1 col-sm-2 col-lg-3"></div>
  </div>
</template>

<script>
//import SoftButton from "@/components/SoftButton.vue";
import SoftField from "@/components/SoftField.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import showSwal from "/src/mixins/showSwal.js";
import PaymentStripe from '@/components/PaymentStripe.vue';
export default {
  name: "Pronostico",
  props: ['uuid'],
  components: {
  //  SoftButton,
    SoftField,
    SoftSwitch,
    PaymentStripe
  },
  data() {  
    return {
      loading: false,
      showError: false,
      resProducto: '',
      errorProducto: '',
      emailLogged: '',
      emailUnlogged: '',
      nameUnlogged: '',
      passwordUnlogged: '',
      codeUnlogged: '',
      activarCompra: false,
      esUsuario: false,
      indacepto: false,
      permitirRegistro: false,
      validarCodigo: false,
      clientSecret: '',
      mostrarUrl: '',
      publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      producto: {id: 0, nombre: '', precio: 0, tipsterid: 0, tipstername: ''}
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  async created() {  
    const response = await this.$store.dispatch("auth/consultaProducto", { uuid: this.uuid  }); 
    this.resProducto = response.res; 
    if (response.res=='OK') {
      this.producto = response.producto ?? {};
    }
    else {
      this.errorProducto = response.mensaje ?? '';
    }
    
    if (this.loggedIn) {
      this.emailLogged = localStorage.getItem("usuarioEmail");
      this.mostrarUrl = localStorage.getItem("mostrarUrl");
      this.passLogged = '';

      this.generarPedido();
    }
    else {
      this.emailUnlogged = '';
      this.nameUnlogged = '';
      this.passwordUnlogged = '';
    }
  },

  beforeMount() {
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = true;
  },

  methods: {
    async validarEmailCliente() {
      const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailPattern.test(this.emailUnlogged)) {
        showSwal.methods.showSwal({
          type: "error",
          message: "El email es erróneo",
        });
      }
      else {
        const response = await this.$store.dispatch("auth/validaEmailUsuario", { email: this.emailUnlogged, uuid: this.uuid }); 
        if (response.res=='OK') {
          if (response.existe) {  console.log('Existe');
            this.esUsuario = true;
            this.permitirRegistro = false;
            this.validarCodigo = false;
          }
          else {  console.log('No existe');
            this.esUsuario = false;
            this.permitirRegistro = true;
            this.validarCodigo = false;
          }
        }      
      }
    },

    async registrarCliente() {
      const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailPattern.test(this.emailUnlogged)) {
        showSwal.methods.showSwal({
          type: "error",
          message: "El email es erróneo",
          width: 600,
        });
      }
      else if (this.nameUnlogged.length<9) {
        showSwal.methods.showSwal({
          type: "error",
          message: "El nombre debe tener más de 10 caracteres",
          width: 600,
        });
      }
      else if (!this.indacepto) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Debe aceptar las condiciones de registro",
          width: 600,
        });
      }
      else{
        try {
            var usuario = {
                email: this.emailUnlogged,
                name: this.nameUnlogged
            };
            
            this.loading = false;
            const resp = await this.$store.dispatch("auth/register", usuario);
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Hemos registrado su usuario",
                    width: 600,
                });
                this.permitirRegistro = false;
                this.validarCodigo = true;
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      }
    },

    async confirmarCodigo() {
      const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailPattern.test(this.emailUnlogged)) {
        showSwal.methods.showSwal({
          type: "error",
          message: "El email es erróneo",
          width: 600,
        });
      }
      else if (this.codeUnlogged.length!=6) {
        showSwal.methods.showSwal({
          type: "error",
          message: "El código consta de 6 números",
          width: 600,
        });
      }
      else{
        try {
            var usuario = {
                email: this.emailUnlogged,
                codigo: this.codeUnlogged
            };
            
            this.loading = false;
            const resp = await this.$store.dispatch("auth/validaCodigo", usuario);
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Hemos registrado su usuario",
                    width: 600,
                });
                this.validarCodigo = false;
                localStorage.setItem("usuarioNombre",this.nameUnlogged);
                localStorage.setItem("usuarioEmail",this.emailUnlogged);
                localStorage.setItem("usuarioRolId",3);
                localStorage.setItem("usuarioTipsterId",0);
                this.emailLogged = this.emailUnlogged;  console.log('Email vale ' + this.emailLogged);
                
                this.generarPedido();
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      }
    },

    async validarSesionCliente() {
      this.loading = true;
      var usuario = {
        usuario: this.emailUnlogged,
        password: this.passwordUnlogged,
      };
      try {
        await this.$store.dispatch("auth/login", usuario);
        localStorage.setItem("usuarioNombre",this.nameUnlogged);
        localStorage.setItem("usuarioEmail",this.emailUnlogged);
        localStorage.setItem("usuarioRolId",3);
        localStorage.setItem("usuarioTipsterId",0);
        this.emailLogged = this.emailUnlogged;  console.log('Email vale ' + this.emailLogged);

        this.generarPedido();
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Datos de acceso erróneos",
        });
      }
      this.loading = false;
    },

    async generarPedido() {
      this.loading = true;
      var pedido = {
        usuario: this.emailLogged,
        producto: this.producto.id,
      };
      try {
        const response = await this.$store.dispatch("auth/generaPedido", pedido);
        if (response.res=='OK') {
          this.clientSecret = response.clientSecret;
          this.refPedido = response.referencia;
          localStorage.setItem("refPedido",this.refPedido);
        }
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Datos de acceso erróneos",
        });
      }    
    },

    async mostrarEnlace(resultado,url) {
      if (resultado=='OK') {
        this.mostrarUrl = url;
        localStorage.setItem("mostrarUrl",url);
      }
      else {
        this.mostrarUrl = '';
        localStorage.setItem("mostrarUrl",'');
      }      
    },

    cambiarAceptacion() {
      this.indacepto = !this.indacepto;
    }, 

    redirigirPedidos() {
      this.$router.push("/pedidos");
    }
  },
};
</script>
<style>
.compra-pronostico {
  display: flex;
  justify-content: center;
  align-items: center;
  height:88vh;
  & > div {width:100%;}
  h1 {
    font-size: 2rem;
    line-height: 4rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
  }
  .nombre-tipster{    
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--bs-gray-600);
  }
}
</style>
