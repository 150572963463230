<template>
  <div class="mt-4 px-4 container-fluid">
    <div class="breadcrumb text-md px-0 mb-4">
      <p><span class="breadcrumb-item" @click="irInicio">Inicio</span> > Usuarios</p>
    </div>
  </div>
  
  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-buscador">
          <div class="titulo"><h5 class="mx-4 mt-5 mb-5">Listado de Usuarios</h5></div>
          <div class="table-search">
            <soft-input id="textoBuscador" type="text" placeholder="Búsqueda rápida..." aria-label="Búsqueda rápida" @input="teclearBuscador"/>
          </div>
          <div class="descarga doble">
            <p @click="descargarCSV"><i class="fa fa-download" aria-hidden="true"></i></p>
            <p @click="abrirAltaUsuario"><i class="fa fa-plus" aria-hidden="true"></i></p>
          </div>
          <div class="borrados">
            <soft-switch id="borrados-switch" name="borrados" @change="cambiarBorrados" :checked="mostrarBorrados"></soft-switch>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card ficha-listado mt-4">
          <div class="px-4 pb-0 card-body">
            <div class="table-responsive mb-4">
              <table id="usuarios-list" class="table table-flush table-listado">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortTable('0')" :class="sortColumn=='0' ? sortDirection : ''">Usuario</th>
                    <th @click="sortTable('1')" :class="sortColumn=='1' ? sortDirection : ''">Nombre</th>
                    <th @click="sortTable('2')" :class="sortColumn=='2' ? sortDirection : ''">Email</th>
                    <th @click="sortTable('3')" :class="sortColumn=='3' ? sortDirection : ''">Rol</th>
                    <th @click="sortTable('4')" :class="sortColumn=='4' ? sortDirection : ''" v-if="miRol==1">Tipster</th>
                    <th @click="sortTable('5')" :class="sortColumn=='5' ? sortDirection : ''" data-type="date" data-format="DD/MM/YYYY">Creado</th>
                    <th @click="sortTable('6')" :class="sortColumn=='6' ? sortDirection : ''" v-if="mostrarBorrados">Eliminado</th>
                    <th data-type="html" data-sortable="false" class="sin-orden">Acciones</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <!-- Ciclo a través de los elementos filtrados y paginados -->
                  <tr v-for="row in filas" :key="row.id">
                    <td>{{ row.username }}</td>
                    <td>{{ row.nombre }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.rol_name }}</td>
                    <td v-if="miRol==1">{{ row.tipster_name }}</td>
                    <td>{{ row.created }}</td>
                    <td v-if="mostrarBorrados">{{ row.deleted }}</td>
                    <td v-html="row.acciones"></td>
                  </tr>
                  <tr v-if="filas.length==0"><td colspan="7">No hay registros que mostrar</td></tr>
                </tbody>
              </table>
            </div>
            <!-- Control de la Paginacion manual -->
            <div class="paginacion" v-if="filas.length>0">
              <p class="contador-listado">Mostrando {{ mostradosIni }} a {{ mostradosFin }} de {{ mostradosTotal }} registros</p>

              <ul class="pagination pagination-md" v-if="paginaTotal>1">
                <li class="page-item prev-page" v-if="paginaActual>1">
                  <a class="page-link" aria-label="Previous" @click="paginaActual=paginaActual-1;fetchAndSetUsuarios();">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </a>
                </li>
                <li v-for="index in rangoPaginas" :key="index" class="page-item" :class="index==paginaActual ? 'active disabled' : ''">
                  <a class="page-link" @click="paginaActual=index;fetchAndSetUsuarios();">{{index}}</a>
                </li>
                <li class="page-item next-page" v-if="paginaActual<paginaTotal">
                  <a class="page-link" aria-label="Next" @click="paginaActual=paginaActual+1;fetchAndSetUsuarios();">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal @cerrar-modal="cerrarModal" :modalActive="modalActive">
    <div class="modal-content">
      <alta-usuario-form v-if="tipoModal=='A'" @cerrar-modal="() => { this.modalActive=false;fetchAndSetUsuarios();}" :key="0"></alta-usuario-form>
      <edita-usuario-form v-if="tipoModal=='E'" @cerrar-modal="() => { this.modalActive=false;fetchAndSetUsuarios();}" :key="usuarioEditId" :usuarioId="usuarioEditId" :usuarioName="usuarioEditName" :usuarioUsername="usuarioEditUsername" :usuarioEmail="usuarioEditEmail" :usuarioRolId="usuarioEditRol" :usuarioTipsterId="usuarioEditTipster"></edita-usuario-form>
      <cambio-clave-usuario-form v-if="tipoModal=='C'" @cerrar-modal="() => { this.modalActive=false;}" :key="2" :usuarioId="usuarioEditId" :usuarioUsername="usuarioEditUsername" :usuarioEmail="usuarioEditEmail"></cambio-clave-usuario-form>
    </div>
  </Modal>

  <div v-if="confirmacionVisible" class="overlay">
    <div class="confirm-box">
      <p class="mt-4 mb-4">{{ mensajeConfirmacion }}</p>
      <table class="mt-4 mb-4"><tr>
        <td width="50%"><button class="btn btn-cancela px-5" @click="cancelacion"><span class="text-sm">Cancelar</span></button></td>
        <td width="50%"><button class="btn btn-confirma px-5" @click="confirmacion"><span class="text-sm">Confirmar</span></button></td>
      </tr></table>
    </div>
  </div>
</template>

<script>
import showSwal from "@/mixins/showSwal.js";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from './components/Modal.vue';
import AltaUsuarioForm from './components/AltaUsuarioForm.vue';
import EditaUsuarioForm from './components/EditaUsuarioForm.vue';
import CambioClaveUsuarioForm from './components/CambioClaveUsuarioForm.vue';
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';

export default {
  name: "Usuarios",
  components: {
    Modal,
    AltaUsuarioForm,
    EditaUsuarioForm,
    CambioClaveUsuarioForm,
    SoftSwitch,
    SoftInput
  },
  mixins: [globalFunctionsMixin],
  data() {
    return {
      filas: [],
      usuarios: [],
      confirmacionVisible: false,
      usuarioId: 0,
      mensajeConfirmacion: '',
      tipoModal: '',
      usuarioEditId: 0,
      usuarioEditUsername: '',
      usuarioEditEmail: '',
      usuarioEditName: '',
      usuarioEditRol: '',
      usuarioEditTipster: '',
      textoBuscador: '',
      paginaActual: 0,
      paginaTotal: 0,
      mostrarBorrados: false,
      mostradosIni: 0,
      mostradosFin: 0,
      mostradosTotal: 0,
      sortColumn: 0,
      sortDirection: '',
      modalActive: false,
      miRol: localStorage.getItem("usuarioRolId")
    };
  },
  computed: {
    rangoPaginas() {
      let startPage = Math.max(this.paginaActual - 3, 1);
      let endPage = Math.min(this.paginaActual + 3,this.paginaTotal);
      let pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },

    async initializeDataTable() {

      this.sortColumn=5;
      this.sortDirection='desc';
      this.textoBuscador = '';
      this.paginaActual = 1;
      await this.fetchAndSetUsuarios();

    },

    sortTable(key) {
      // Alternar entre ascendente y descendente al hacer clic en el encabezado
      if (this.sortColumn === key) {
        this.sortDirection = this.sortDirection=='asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = key;
        this.sortDirection = 'asc';
      }
      this.fetchAndSetUsuarios();
    },

    teclearBuscador(event) {
      this.textoBuscador = event.target.value;

      let searchTimeout;
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        this.fetchAndSetUsuarios();
      }, 500);
    },

    async fetchAndSetUsuarios() {
      try {
        // Petición al servidor
        const response = await this.$store.dispatch("auth/usuarios", { textoBuscador: this.textoBuscador, paginaActual: this.paginaActual, mostrarBorrados: this.mostrarBorrados, sortColumn: this.sortColumn, sortDirection: this.sortDirection  }); 
        this.usuarios = response.data; console.log('Resp',response);

        // Procesar los datos y actualizarlos en el DataTable
        this.filas = response.data.map(row => ({
          username: row.username,
          nombre: row.nombre,
          email: row.email,
          rol_name: row.rol_name,
          tipster_name: row.tipster_name,
          created: this.formatDate(row.created_at, 'DD/MM/YYYY'),
          deleted: row.deleted_at ? this.formatDate(row.deleted_at, 'DD/MM/YYYY') : '',
          acciones: row.deleted_at ? '' : 
            `<a href="#" class="editButton cursor-pointer me-3" data-bs-toggle="tooltip" title="Editar Usuario">
              <i class="fas fa-user-edit text-secondary" data-rel="${row.id}"></i>
            </a>` +
            `<a href="#" class="removeButton cursor-pointer me-3" data-bs-toggle="tooltip" title="Eliminar Usuario">
              <i class="fas fa-trash text-secondary" data-rel="${row.id}"></i>
            </a>` + 
            `<a href="#" class="passwordButton cursor-pointer me-3" data-bs-toggle="tooltip" title="Cambiar clave Usuario">
              <i class="fas fa-key text-secondary" data-rel="${row.id}"></i>
            </a>`
        }));

        this.paginaTotal = response.paginas;
        this.mostradosIni = (response.actual-1)*response.porpagina + 1;
        this.mostradosFin = Math.min(response.totales,response.actual*response.porpagina);
        this.mostradosTotal = response.totales;

        this.activarBotoneraListado();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    mostrarConfirmacionBorrar(id) { 
      this.confirmacionVisible = true;
      this.usuarioId = id;
      this.mensajeConfirmacion = '¿Confirma que desea eliminar este usuario?';
    },
    confirmacion() {
      this.confirmacionVisible = false;
      this.borrarUsuario(this.usuarioId);
    },
    cancelacion() {
      this.confirmacionVisible = false;
    },
    async abrirAltaUsuario() {
      this.tipoModal = 'A';
      this.modalActive = true; 
    },
    cerrarModal() {
      this.modalActive = false;
    },
    async cambiarBorrados() {
      this.mostrarBorrados=!this.mostrarBorrados; 
      await this.initializeDataTable();
    },
    async borrarUsuario(usuarioId) {
      try {
        const resp = await this.$store.dispatch("auth/borraUsuario",{id: usuarioId});
        if (resp.res=='OK') {
            showSwal.methods.showSwal({
                type: "success",
                message: resp.mensaje,
                width: 600,
            });
            this.fetchAndSetUsuarios();
            this.$emit('cerrar-modal');
        }
        else {
            showSwal.methods.showSwal({
                type: "error",
                message: resp.mensaje,
                width: 500
            });               
        }
      } 
      catch (error) {
        showSwal.methods.showSwal({
            type: "error",
            message: "Se ha producido un error",
            width: 500,
        });
      }
    },
    activarBotoneraListado() {
      const tableBody = document.querySelector('#usuarios-list tbody');
      tableBody.addEventListener('click', (event) => {
        if (event.target.classList.contains('fa-user-edit')) {  // Se pulsa EDIT
          this.$nextTick(() => { 
            this.modalActive = true; 
            this.tipoModal='E'; 
            for (let row of this.usuarios) {
              if (row.id==event.target.dataset.rel) {
                this.usuarioEditId=row.id;
                this.usuarioEditUsername=row.username;
                this.usuarioEditEmail=row.email;
                this.usuarioEditName=row.nombre;
                this.usuarioEditRol=row.rol_id.toString();
                this.usuarioEditTipster=row.tipster_id.toString();
              }
            }
          });
        }
        if (event.target.classList.contains('fa-trash')) {  // Se pulsa TRASH
          this.mostrarConfirmacionBorrar(event.target.dataset.rel);
        }  
        if (event.target.classList.contains('fa-key')) {  // Se pulsa KEY
          this.$nextTick(() => { 
            this.modalActive = true; 
            this.tipoModal='C'; 
            for (let row of this.usuarios) { 
              if (row.id==event.target.dataset.rel) { 
                this.usuarioEditId=row.id;
                this.usuarioEditUsername=row.username;
                this.usuarioEditEmail=row.email;
              }
            }
          });
        }     
      });
    },
    irInicio() {
      this.$router.push('/dashboard');
    }
  },

  async mounted() { 
    this.$store.state.showFooter = true;
    this.initializeDataTable();
  },

};
</script>



<style lang="scss" scoped>
.usuarios {
  background-color: rgba(0, 176, 234, 0.5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    h1,
    p {
      margin-bottom: 16px;
    }

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
